<div [ngSwitch]="template">
  <section *ngSwitchCase="'home'" class="modulo modulo-contato-home default-home" itemscope itemtype="https://schema.org/Organization">
    <div class="container">   
      <h2 class="wow animTypeWriter" data-delay="0.5s">Nossos canais de atendimento</h2>
      <div class="list-contato">
        <div class="item wow fadeIn" data-wow-delay="0.5s" (click)="openWhatsApp()">
          <img lazyLoad="/images/template/icons/whatsapp-2.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="WhatsApp" />
          <div class="text">
            <strong>WhatsApp</strong><br>
            <span class="value">{{data.params.whatsapp}}</span>
          </div>  
        </div>
        <div class="item wow fadeIn" data-wow-delay="0.7s">
          <img lazyLoad="/images/template/icons/telefone.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Telefone" />
          <div class="text">
            <strong>Telefone</strong><br>
            <a href="tel:{{data.params.celular}}" class="value" title="Telefone">{{data.params.celular}}</a>  
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="0.8s">
          <img lazyLoad="/images/template/icons/email.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Email" />
          <div class="text">
            <strong>Email</strong><br>
            <a href="mailto:{{data.params.email}}" class="value" title="Email">{{data.params.email}}</a>
          </div>  
        </div> 
        <div class="item wow fadeIn" data-wow-delay="1s"> 
          <img lazyLoad="/images/template/icons/instagram-3.svg" defaultImage="/images/pixel/bg.png" width="50px" height="50px" alt="Instagram" />
          <div class="text">
            <strong>Instagram</strong><br>
            <a href="{{data.params.instagram_link}}" target="_blank" class="value" title="Instagram">{{data.params.instagram_conta}}</a>
          </div>  
        </div>   
      </div>
      <div class="bg-contato"></div>
    </div>
  </section>
  <div *ngSwitchCase="'footer'" class="contato-footer">
    <h3>Nossos Contatos</h3>
    <div class="item">
      <strong>E-mail</strong>
      <span>{{data.params.email}}</span>
    </div>
    <div class="item">
      <strong>Telefone</strong>
      <a href="tel:{{data.params.whatsapp}}">{{data.params.whatsapp}}</a>
    </div> 
  </div>
  <div *ngSwitchCase="'email'" class="default-email">
    <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/email.svg" alt="Telefone" width="40px" height="40px" />
    <span>{{data.params.email}}</span> 
  </div> 
  <div *ngSwitchCase="'telefone'" class="default-email">
    <h3 class="modulo-title">{{'Telefone' | translate}}:</h3> 
    <a href="tel:{{data.params.celular}}" title="Telefone">{{data.params.celular}}</a>
  </div>
  <div *ngSwitchCase="'page-contato-telefone'" class="default-list page-contato-telefone">
    <div class="icon">
      <i class="material-icons color-white">
        phone_enabled
      </i>
    </div> 
    <div class="content"> 
      <span class="title">Ligue Agora</span>
      <a href="tel:{{data.params.celular}}" title="Telefone">{{data.params.whatsapp}}</a>
    </div>
  </div>  
  <div *ngSwitchCase="'page-contato-email'" class="default-list page-contato-email page-contato-telefone">
    <div class="icon bg-yellow">
      <i class="material-icons color-white">
        email
      </i>
    </div> 
    <div class="content">
      <span class="title">{{'Nos envie um e-mail' | translate}}</span>
      <a href="mailto:{{data.params.email}}" title="E-mail">{{data.params.email}}</a>
    </div>
  </div>
  <div *ngSwitchCase="'topo'">
    <ul class="ul-phones">
      <li class="phone">
          <img src='/images/icons/phone.svg' alt="Telefone" width="20px" />
          <a href="tel:{{data.params.telefone1}}" title="Telefone">
            {{data.params.telefone1}}   
          </a>    
      </li>
      <li class="divisor">
          <span></span> 
      </li>
      <li class="phone"> 
          <a href="tel:{{data.params.telefone2}}" title="Telefone">
            {{data.params.telefone2}}
          </a>  
      </li>
  </ul> 
  </div>
  <div *ngSwitchCase="'telefone-servico'" class="telefone-servico">
    <h3>Atendimento por telefone:</h3> 
    <ul>
      <li *ngIf="data.params.suporte_telefone1 != '' && data.params.suporte_telefone1 != null">
        <i class="material-icons">local_phone</i>
        <span>{{data.params.suporte_telefone1}}</span>
      </li> 
      <li *ngIf="data.params.suporte_telefone2 != '' && data.params.suporte_telefone2 != null">
        <i class="material-icons">local_phone</i>
        <span>{{data.params.suporte_telefone2}}</span>
      </li>  
    </ul>  
  </div>   
</div>  

