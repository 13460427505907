<header class="header template-default">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-5 col-logo">
                    <modulo posicao="logo"></modulo>  
                </div>
                <div class="col-md-9 col-center col-7">    
                    <div class="content-action">
                        <div class="item item-cart">  
                            <btn-cart></btn-cart>   
                        </div>
                        <div class="item item-user"> 
                            <btn-usuario-login [usuarioService]="usuarioService"></btn-usuario-login>
                        </div> 
                        <div class="item item-menu">   
                            <a href="javascript::void();" title="Menu" (click)="_showMenu()">
                                <i class="fa-solid fa-bars"></i>
                                <span>Menu</span>
                            </a> 
                        </div>
                    </div>    
                </div>
            </div>    
        </div>     
    </div>    
</header>  
<sidebar-menu [showMenu]="showMenu"></sidebar-menu>   